module.exports = {
  environment: 'development',
  url: 'http://formwizard.local',
  api: {
    urls: {
      wizard: {
        http: 'http://formwizard-api.local',
      },
    },
  },
  analytics: {
    segmentKey: 'plm2lXqMglQga7iC9FNlQJJeaH3Apy22',
  },
  deployment: {
    commit: '',
    branch: '',
    deployedAt: '',
    s3Bucket: '',
  },
  webpackDevServer: {
    port: 80,
    publicPath: 'formwizard.local:80',
  },
  jabber: {
    userHost: 'jabber.local',
    mucHost: 'conference.jabber.local',
    wsUrl: 'ws://jabber.local/ws',
  },
  appsignal: {
    key: null,
  },
};
