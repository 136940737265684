const nodeEnv = process.env.NODE_ENV || 'development';
let appEnv = process.env.APP_ENV || 'development';

// When we're running tests we do it with the development environment
if (appEnv == 'test') {
  appEnv = 'development';
}

const isBuild = (process.env.BUILD || 'false') === 'true';
const isRemote = nodeEnv === 'production';
const isLocal = !isRemote;

// eslint-disable-next-line import/no-dynamic-require,global-require
const config = require(`./${appEnv}.js`);

module.exports = {
  env: appEnv,
  config,
  isBuild,
  isRemote,
  isLocal,
};
