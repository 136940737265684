module.exports = {
  environment: 'production',
  url: 'https://formwizard.hausgold.de',
  api: {
    urls: {
      wizard: {
        http: 'https://formwizard-api.hausgold.de',
      },
    },
  },
  analytics: {
    segmentKey: 'KfG4s36wg3bVv2j5jsgHQEaN5ynN4njL',
  },
  deployment: {
    commit: '',
    branch: '',
    deployedAt: '',
    s3Bucket: '',
  },
  webpackDevServer: {
    port: 80,
    publicPath: 'localhost:80',
  },
  jabber: {
    userHost: 'jabber.hausgold.de',
    mucHost: 'conference.jabber.hausgold.de',
    wsUrl: 'wss://jabber.hausgold.de/ws',
  },
  appsignal: {
    key: '034e374b-4d78-41f5-af6d-a14047dd7261',
  },
};
